import React from "react";
// import LazyImage from "../../molecules/layout/Images/LazyImage";
import Image from "@image";

function JoinOurCommunity() {
    return (
        <section className="bg-dark text-white">
            <div className="container py-4">
                <div className="row my-5 justify-content-center px-md-5">
                    <h3 className="text-center">
                        We now have{" "}
                        <span className="intro-text rate">over 50 homes</span>{" "}
                        at various stages of construction!
                    </h3>
                </div>
                {/* <div className="row my-5 justify-content-center">
                    <div className="col-1  col-lg-3 d-flex justify-content-center">
                        <Image
                            src="/images/home/dots_small.svg"
                            className=""
                            height={"25px"}
                            width={"200px"}
                        />
                    </div>
                    <div className="col col-md-6 col-lg-3 d-flex justify-content-center">
                        <a
                            className="analytics-join-telegram-community-link btn btn-light"
                            href="https://t.me/joinchat/ZLDpYjuL8qtkNGJk"
                            target="_blank"
                            rel="noopener"
                        >
                            View Plans
                        </a>
                    </div>
                    <div className="col-1 col-lg-3 d-flex justify-content-center">
                        <Image
                            src="/images/home/dots_small.svg"
                            className=""
                            height={"25px"}
                            width={"200px"}
                        />
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default JoinOurCommunity;
