import React from "react";
import dynamic from "next/dynamic";

// it only works when lazy loaded
const SubscribeToEmailListComponent = dynamic(
    () => import("./SubscribeToEmailListComponent"),
    { ssr: false }
);

export default function SubscribeToEmailList() {
    return (
        <>
            <SubscribeToEmailListComponent />
        </>
    );
}
