import React from "react";
import Link from "next/link";

function NeedMore() {
    return (
        <section
            className="  py-8 bg-soft"
            // style={{
            //     background:
            //         "url('https://images.unsplash.com/photo-1594237926304-3e833086e6ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2102&q=80') no-repeat center",
            // }}
        >
            <div className="container ">
                <div className="row text-dark">
                    <div className="col-12 col-md-6">
                        <h3 className="mb-5">Need more?</h3>
                        <ul className="spaced-list">
                            <li>
                                While we do not offer custom plans, we can make
                                minor adjustments to an Inuua plan, to help you
                                to get the house that will suit your needs.
                            </li>
                            <li>
                                If you are interested in hiring a supervisor or
                                contractor to work on your project, we are happy
                                to recommend some to our customers.
                            </li>
                            <li>
                                Put your creative stamp on your design by
                                choosing your preferred cladding or finishes.
                            </li>
                            <li>
                                To approach your bank for financing, we can
                                furnish you with the Bill of Quantities at an
                                added cost on your selected plan so the bank can
                                understand your project.
                            </li>
                            <li>
                                We are working to get our plans pre-approved in
                                Greater Nairobi (Kajiado, Kiambu, Machakos). In
                                the meantime, we can support you to get county
                                approvals for your Inuua project.
                            </li>
                        </ul>
                        <div className="d-flex justify-content-start my-5">
                            <Link href="/plans">
                                <a className="analytics-view-plans-link btn btn-dark">
                                    Take me to my plan
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="col-12 col-md-6 px-4 bg-dark text-white need-more-dark"
                        style={
                            {
                                // backgroundColor: "rgba(243,246,251, 0.14)",
                            }
                        }
                    >
                        {/* <div
                            style={{
                                backgroundColor: "rgba(92,192,192, 0.45)",

                                borderRadius: "15px",
                            }}
                        ></div> */}
                        <h3 className="mt-3 mb-5">
                            Your Time & Money are valuable. We want to help you
                            avoid
                        </h3>
                        <ul className="spaced-list">
                            <li>
                                The expense of hiring your own architect and
                                Quantity Surveyor.
                            </li>
                            <li>Confusion about the building process.</li>
                            <li>Building an untested design.</li>
                            <li>Painstaking manual price comparisons.</li>
                            <li>Loss of materials on your site.</li>
                            <li>Losing track of your project expenses.</li>
                        </ul>
                        <div className="d-flex justify-content-start my-5">
                            <Link href="/plans">
                                <a className="analytics-view-plans-link btn btn-light">
                                    Ok, I'm ready to choose
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NeedMore;
