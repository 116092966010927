import React, { useEffect } from "react";
import Image from "@image";
import Testimonials from "../../../organisms/Testimonials/Testimonials";
import InuuaPartners from "../../../organisms/Common/InuuaPartners";
import HowItWorks from "./HowItWorks";
import InuuaIsMuchMoreThanAHousePlan from "./InuuaIsMuchMoreThanAHousePlan";
import AffordableBeautifulHomeDesign from "./AffordableBeautifulHomeDesign";
import BuildStraightFromYourPhone from "./BuildStraightFromYourPhone";
import SaveUpTo40Percent from "./SaveUpTo40Percent";
// import JoinOurCommunity from "../../../organisms/Common/JoinOurCommunity";
import HomesBuilt from "../../../organisms/Common/HomesBuilt";
import NeedMore from "../../../organisms/Common/NeedMore";
// import LazyImage from "../../../molecules/layout/Images/LazyImage";
import SubscribeToEmailList from "../../../organisms/Common/SubscribeToEmailList";

export default function HomePage() {
    return (
        <section className="">
            <Intro />
            <HowItWorks />
            <HomesBuilt />
            <Testimonials />
            <InuuaIsMuchMoreThanAHousePlan />
            <AffordableBeautifulHomeDesign />
            <BuildStraightFromYourPhone />
            <hr />
            <SaveUpTo40Percent />
            {/* <JoinOurCommunity /> */}
            <NeedMore />
            <InuuaPartners />
            <SubscribeToEmailList />
        </section>
    );
}

function Intro() {
    //
    // place background image
    useEffect(() => {
        var lazyBackgrounds = [].slice.call(
            document.querySelectorAll(".lazy-homepage-background")
        );

        if ("IntersectionObserver" in window) {
            let lazyBackgroundObserver = new IntersectionObserver(function (
                entries,
                observer
            ) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");

                        lazyBackgroundObserver.unobserve(entry.target);
                    }
                });
            });

            lazyBackgrounds.forEach(function (lazyBackground) {
                lazyBackgroundObserver.observe(lazyBackground);
            });
        }
    }, []);

    return (
        <>
            {/* <-------------------------------  Start with our great Kenyan house plans -------------------------------> */}
            <section
                id="inuua-intro"
                className="lazy-homepage-background d-flex flex-column align-items-center header-bg section-header no-pad-sm sec-overlay"
                style={{ height: "700px" }}
            >
                <div className="sec-overlay">
                    {/* <div className="container header-bg">
                <div className="row justify-content-between pt-lg-0 pt-4">
                    <div className="col-12 col-md-7 order-2 order-lg-1 pr-4">
                        <div className="intro-text pt-lg-0 pt-3">
                            Start with our great Kenyan house plans and manage your site to save up to <span className="rate">40%</span>!
                        </div>
                        <div className="col-lg-4 col-md-12 mt-5 px-0">
                            <a className="btn btn-light btn-block" href="#house-types">Browse Plans</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

                    <div className="container pb-10">
                        <div className="row">
                            <div className="col col-12 col-lg-7">
                                <h1 className="intro-text">
                                    Building your home can become a real
                                    headache
                                </h1>
                                <h5 className="text-white mt-1 fs-3 font-weight-light">
                                    Our simple system offers modern house plans
                                    - each with realistic cost projection,
                                    access to suppliers near you and tools to
                                    manage your project every step of the way.
                                </h5>

                                <div className="w-50 my-3">
                                    <span id="intro-red-line">
                                        <hr />
                                    </span>
                                </div>
                                <h3 className="text-white ">
                                    Simplify your life and save up to{" "}
                                    <span className="rate">40%</span>!
                                </h3>

                                <div className="row  mr-md-5 pr-md-5">
                                    <div className="mt-4 col-sm-6 d-flex flex-column align-items-center justify-content-start">
                                        <a
                                            className="analytics-view-plans-link btn btn-light default-button"
                                            href="#house-types"
                                        >
                                            Browse Plans
                                        </a>
                                        {/* <p
                                            className="mt-2 text-center text-white fw-light font-weight-light"
                                            style={{ fontSize: "small" }}
                                        >
                                            Select your design
                                        </p> */}
                                    </div>
                                    <div className="mt-4 col-sm-6 d-flex flex-column align-items-center justify-content-center">
                                        {/* <a
                                            className="analytics-inuua-main-app-on-playstore-link default-button"
                                            href="https://play.google.com/store/apps/details?id=com.inuuaprojectmanagement"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <LazyImage
                                                className="analytics-inuua-main-app-on-playstore-link default-button"
                                                src="/images/GetItOnGooglePlayBadge.svg"
                                                alt="Inuua app on Google PlayStore"
                                            />
                                        </a> */}

                                        {/* <p
                                            className="mt-2 text-center text-white font-weight-light"
                                            style={{ fontSize: "small" }}
                                        >
                                            Download Inuua app
                                        </p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col col-12 col-lg-5 d-flex align-item-end justify-content-center">
                                <div className="pt-4 d-flex flex-column justify-content-center align-items-center m-auto ml-md-0 m-lg-auto">
                                    <a
                                        href="/nachu"
                                        target="_blank"
                                        rel="noopener"
                                        className="rounded shadow-lg"
                                    >
                                        <Image
                                            className="rounded"
                                            src="/images/nachu/InuuaNaNachuLogoPlusRiverLineRidgesLogo.png"
                                            alt="Inuua na nachu & River line ridges logo"
                                            height="150px"
                                            width="350px"
                                            quality={100}
                                            layout="fixed"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col" />
                </div>
            </section>
        </>
    );
}
