import React from "react";

export default function BuildStraightFromYourPhone() {
    return (
        <section id="BuildStraightFromYourPhone" className="container section ">
            <div className="row justify-content-center my-2 mx-md-4 mx-md-5">
                <h2 className="text-center mb-4">
                    Build your dream home straight from your phone using Inuua
                </h2>
                <p className="text-center">
                    Download the Inuua App and find a world of helpful tools and
                    resources to help you along your construction journey. The
                    app gives you access to a community of helpful experts and
                    individuals building their homes. See features below:
                </p>
            </div>
            <div className="row  ">
                <div className="col-12 col-lg-6 my-5">
                    <div className="embed-responsive embed-responsive-16by9 shadow">
                        <iframe
                            src="https://player.vimeo.com/video/447467111"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
                <div className="col-12 col-lg-6 my-5">
                    {/* bullet points */}
                    <div className="row ">
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 1</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Choose a beautiful, well thought out,
                                        modern architectural design
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 2</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Track your materials and labour costs at
                                        your pace and monitor your progress
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 3</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Use our builders guide for expert advice
                                        and resources at builders like you,
                                        every stage of construction.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 4</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Access suppliers near your site. Compare
                                        quotes right from your phone and have
                                        your goods delivered.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 5</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Use our Builder's Guide in the app for
                                        expert advice and resources for every
                                        stage of construction.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-md-2">
                            <div className="row d-flex justify-content-center">
                                <div className="col-1 font-weight-bold">
                                    <p className="h2 text-dark"> 6</p>
                                </div>
                                <div className="col-10">
                                    <p>
                                        Join a community of home builders like
                                        you!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end bullet points */}
                </div>
            </div>
        </section>
    );
}
