import React from "react";
import Link from "next/link";
import Image from "@image";
import LazyImage from "../../../molecules/layout/Images/LazyImage";

function AffordableBeautifulHomeDesign() {
    return (
        <section id="house-types" className="container section">
            {/* <div className="container my-5"> */}
            <div className="row mx-md-3">
                <div className="col-12 mt-5">
                    <h2 className="text-center">
                        Begin with an affordable and beautiful design
                    </h2>
                </div>
                <div className="col-12 my-3 my-lg-4">
                    <p className="text-center">
                        Select from a wide variety of house designs to match
                        your taste. Access professional architectural designs
                        for only Ksh 20,000 with any plan you select. This
                        massive saving and stress saver is just the first of
                        many Inuua offers during your construction journey.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 my-3 col-lg-6">
                    <div
                        style={{ borderRadius: "15px" }}
                        className="container py-3 px-2 bg-soft"
                    >
                        <div className="row">
                            <div
                                className="col-12 col-md-6 d-flex align-items-center justify-content-center"
                                // style={{ height: "275px", width: "150px" }}
                            >
                                <Image
                                    src="/images/BungalowHomePage.jpg"
                                    className="float-left my-auto rounded-lg"
                                    height={"275px"}
                                    width={"275px"}
                                    // style={{
                                    // objectFit: "cover",
                                    // }}
                                />
                            </div>
                            <div className="col my-1 pt-5">
                                <div className="pb-2 mr-md-5 ">
                                    <h4>Bungalow</h4>

                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bath text-dark pr-2"></i>
                                        <p className="m-0">2-4 bathrooms</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bed text-dark pr-2"></i>
                                        <p className="m-0">2-3 bedrooms</p>
                                    </div>
                                </div>
                                <div className="pb-2 mr-md-5 ">
                                    <Link href="/plans?house_type=bungalow">
                                        <a className="analytics-view-plans-link col btn btn-dark mt-4">
                                            Browse designs
                                        </a>
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
                <div className="col-12 my-3 col-lg-6">
                    <div
                        style={{ borderRadius: "15px" }}
                        className="container py-3 px-2 bg-soft"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <Image
                                    src="/images/MaisonetteHomePage.jpg"
                                    className="float-left my-auto rounded-lg"
                                    height={"275px"}
                                    width={"295px"}
                                    // layout="responsive"
                                    // style={{
                                    //     objectFit: "cover",
                                    // }}
                                />
                            </div>
                            <div className="col my-1 pt-5">
                                <div className="pb-2 mr-md-5 ">
                                    <h4>Maisonette</h4>

                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bath text-dark pr-2"></i>
                                        <p className="m-0">3-5 bathrooms</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bed text-dark pr-2"></i>
                                        <p className="m-0">3+ bedrooms</p>
                                    </div>
                                </div>
                                <div className="pb-2 mr-md-5 ">
                                    <Link href="/plans?house_type=maisonette">
                                        <a className="analytics-view-plans-link col btn btn-dark mt-4">
                                            Browse designs
                                        </a>
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
                <div className="col-12 my-3 col-lg-6">
                    <div
                        style={{ borderRadius: "15px" }}
                        className="container py-3 px-2 bg-soft"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <Image
                                    src="/images/bedsitter-interiorHomePage.jpg"
                                    className="float-left my-auto rounded-lg"
                                    height={"275px"}
                                    width={"275px"}
                                    // style={{
                                    // objectFit: "cover",
                                    // }}
                                />
                            </div>
                            <div className="col my-1 pt-5">
                                <div className="pb-2 mr-md-5 ">
                                    <h4>Bedsitters</h4>

                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bed text-dark pr-2"></i>
                                        <p className="m-0">Self contained</p>
                                    </div>
                                    <div className="d-flex justify-content-start py-3">
                                        {/* <i className="fas fa-bath text-dark pr-2"></i>
                                        <p className="m-0">bathrooms</p> */}
                                    </div>
                                </div>
                                <div className="pb-2 mr-md-5 ">
                                    <Link href="/plans?house_type=bedsitter">
                                        <a className="analytics-view-plans-link col btn btn-dark mt-4">
                                            Browse designs
                                        </a>
                                    </Link>
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 my-3 col-lg-6">
                    <div
                        style={{ borderRadius: "15px" }}
                        className="container py-3 px-2 bg-soft"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <Image
                                    src="/images/office-module-interiorHomePage.jpg"
                                    className="float-left my-auto rounded-lg"
                                    height={"275px"}
                                    width={"275px"}
                                    // style={{
                                    // objectFit: "cover",
                                    // }}
                                />
                            </div>
                            <div className="col my-1 pt-5">
                                <div className="pb-2 mr-md-5 ">
                                    <h4>DSQ & Home office</h4>

                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bath text-dark pr-2"></i>
                                        <p className="m-0">1-2 bathrooms</p>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <i className="fas fa-bed text-dark pr-2"></i>
                                        <p className="m-0">0-1 bedrooms</p>
                                    </div>
                                </div>
                                <div className="pb-2 mr-md-5 ">
                                    <Link href="/plans?house_type=module">
                                        <a className="analytics-view-plans-link col btn btn-dark mt-4">
                                            Browse designs
                                        </a>
                                    </Link>
                                </div>
                            </div>{" "}
                        </div>
                    </div>
                </div>
                <div className="col-12 my-3 col-lg-8">
                    <div
                        style={{ borderRadius: "15px" }}
                        className="container py-3 px-2 bg-soft"
                    >
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <Image
                                    src="/images/jenga pole pole HomePage.jpg"
                                    className="float-left my-auto rounded-lg"
                                    height={"275px"}
                                    width={"295px"}
                                    // style={{
                                    // objectFit: "cover",
                                    // }}
                                />
                            </div>
                            <div className="col pt-1">
                                <div className="mr-md-5">
                                    <h4>Inuua Chap Chap</h4>

                                    <div className="d-flex justify-content-start">
                                        <p className="m-0">
                                            Inuua Chap Chap is our construction
                                            concept that enables you to complete
                                            the most critical part of your home,
                                            so you can move in and add the rest
                                            of the rooms at your own pace
                                            without the burden of also paying
                                            monthly rent.
                                        </p>
                                    </div>
                                </div>
                                <div className="pb-1 mr-md-5 pr-lg-5">
                                    <Link href="/plans?house_type=jengapolepole">
                                        <a className="analytics-view-plans-link col btn btn-dark mt-4">
                                            Browse designs
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                </div>
                <div className="col-1 my-3 col-lg-4 py-0 py-md-5 text-center">
                    <Image
                        src="/images/home/dots.svg"
                        className=""
                        height={"25px"}
                        width={"200px"}
                    />
                </div>
            </div>
        </section>
    );
}

export default AffordableBeautifulHomeDesign;
